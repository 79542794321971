<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="公告标题">
            <el-input v-model.trim="params.notice_title" placeholder="请输入公告标题" clearable></el-input>
          </el-form-item>
          <el-form-item label="发布状态" label-width="70px">
            <el-select style="width: 100px" v-model="params.publish_status" size="small" placeholder="请选择" clearable>
              <el-option label="全部" value="" />
              <el-option label="未发布" :value="0" />
              <el-option label="已发布" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="发布时间" label-width="70px">
            <el-date-picker style="width: 220px" v-model="order_time_range" type="datetimerange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <span class="el-icon-warning"></span>
            <span>公告发布仅针对一件代发商城有效</span>
          </el-form-item>
          <el-form-item>
            <el-button @click="addNotice()" type="primary" size="small">发布公告</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="公告标题">
          <template slot-scope="scope">{{ scope.row.notice_title }}</template>
        </el-table-column>
        <el-table-column label="发布状态" width="90">
          <template slot-scope="scope">{{ scope.row.publish_status | account_type_filter }}</template>
        </el-table-column>
        <el-table-column label="发布时间" width="160">
          <template slot-scope="{row}">{{ row.published_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="操作人" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.update_by_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="{row}">
            <el-button size="mini" type="primary" @click="addNotice(row)">修改</el-button>
            <el-button size="mini" type="danger" @click="deletez(row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--修改|新增-->
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible">
      <el-form :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="公告标题" prop="notice_title">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入公告内容，不超过200字"
            :maxlength="200" v-model="txInfo.notice_title"></el-input>
        </el-form-item>
        <el-form-item label="发布时间" prop="publish_immediately">
          <el-radio-group :disabled="txInfo.publish_status == 1" v-model="txInfo.publish_immediately">
            <el-radio :label="1">立即发布</el-radio>
            <el-radio :label="0">延时发布</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="txInfo.publish_immediately == 0" prop="published_time">
          <el-date-picker :disabled="txInfo.publish_status == 1" v-model="txInfo.published_time"
            value-format="timestamp" type="datetime" placeholder="请选择发布时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item style="text-align:right">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="accountSubmit">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { enterpriseNotice, addEnterpriseNotice, editEnterpriseNotice, delEnterpriseNotice, infoEnterpriseNotice } from '@/api/info';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
export default {
  name: 'noticeIdentify',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      editVisible: false,//审核页
      order_time_range: [],
      loadingtitle: '',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        notice_title: '',
        publish_immediately: 1,
        published_time: '',
      },
      txRules: {//审核表单验证
        notice_title: [{
          required: true,
          message: '公告内容不得为空',
          trigger: 'blur'
        }],
        publish_immediately: [{
          required: true,
          trigger: 'change'
        }],
        published_time: [{
          required: true,
          message: '发布时间不得为空',
          trigger: 'change'
        }],
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        publish_status: ''
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**发布状态 */
    account_type_filter: value => {
      switch (value) {
        case 0:
          return '未发布';
        case 1:
          return '已发布';
      }
    }
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      if (//格式化时间选择器
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_published_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_published_time =
            new Date(this.order_time_range[1]).getTime() / 1000;
        } else {
          this.params.start_published_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_published_time =
            this.order_time_range[1].getTime() / 1000;
        }
      } else {
        delete this.params.start_published_time
        delete this.params.end_published_time
      }
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭审核认证弹窗 */
    cancel () {
      this.editVisible = false//审核页
    },
    /**打开认证审核 */
    addNotice (row = null) {
      if (row) {//编辑
        infoEnterpriseNotice(row.id).then((response) => {
          this.loadingtitle = "修改公告"
          this.txInfo = {//审核表单内容
            notice_title: response.notice_title,
            publish_immediately: response.publish_immediately,
            published_time: response.published_time * 1000,
            id: response.id,
            publish_status: response.publish_status
          }
          this.editVisible = true
        })
      } else {//新增
        this.loadingtitle = "发布公告"
        this.txInfo = {//审核表单内容
          notice_title: '',
          publish_immediately: 1,
          published_time: '',
        }
        this.editVisible = true
      }
    },
    /**提交 */
    accountSubmit () {
      this.$refs.txInfo.validate(valid => {
        if (valid) {
          if (this.txInfo.id) {//编辑
            let datas;
            if (this.txInfo.publish_status == 1) {
              datas = {
                id: this.txInfo.id,
                notice_title: this.txInfo.notice_title,
              }
            } else {
              datas = {
                ...this.txInfo
              }
              datas.published_time = datas.published_time / 1000
            }
            editEnterpriseNotice(datas).then((response) => {
              this.$message.success('公告修改成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          } else {//添加
            let datas;
            datas = {
              ...this.txInfo
            }
            datas.published_time = datas.published_time / 1000
            addEnterpriseNotice(datas).then((response) => {
              this.$message.success('公告提交成功！');
              this.editVisible = false;
              this.GET_AccountList();
            })
          }
        }
      });
    },
    deletez (row) {//删除
      this.$confirm("确定要删除该公告吗?", "提示", { type: "warning" }).then(() => {
        delEnterpriseNotice(row.id).then(res => {
          this.$message.success("删除成功");
          this.GET_AccountList();
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      enterpriseNotice(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-textarea__inner {

  border-radius: 0;

}
</style>
